import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useCustomTranslation from 'hooks/useCustomTranslation';

export default function ColorToggleButton({ alignment, onChange }) {
  const { t } = useCustomTranslation();

  //make that into key value pair
  const options = [
    { key: 'client', value: t('Client') },
    { key: 'employee', value: t('Employee') },
    { key: 'activity', value: t('Activity') }
  ];

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={onChange}
      aria-label="Platform"
    >
      {options.map((option) => (
        <ToggleButton key={option.key} value={option.key} aria-label={option.value}>
          {option.value}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
