import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


export default function DatePickerValue({ formik, localeFormat }) {
  const defaultValue = dayjs(formik.values.date) || '';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={localeFormat("Date")}
        value={defaultValue}
        format={'MM/DD/YYYY'}
        onChange={(value) => {
          console.log(value?.format()); // Ensure it's valid
          formik.setFieldValue('date', value);
        }}
      />
    </LocalizationProvider>
  );
}