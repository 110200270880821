import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import useCustomTranslation from 'hooks/useCustomTranslation';

export default function StandaloneToggleButton({ selected, setSelected }) {
  const { t } = useCustomTranslation();

  return (
    <ToggleButton
      value="check"
      selected={selected}
      onChange={() => setSelected((prevSelected) => !prevSelected)}
    >
      {t('Choose other dates')}
    </ToggleButton>
  );
}