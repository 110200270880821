import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from 'dayjs';

const Timer = ({
  localeFormat,
  isRunning,
  isStopped,
  sendValuesToParent,
  sendTimeToParent,
  sendPriceToParent,
  formik,
  elapsedTime,
  currentPrice,
  setElapsedTime,
  setCurrentPrice,
  isEditing,
  isAgain,
  activity,
  setActivity
}) => {
  const [intervalId, setIntervalId] = useState(null);
  const startTimeRef = useRef(null);
  const intervalIdsRef = useRef([]); // Store all interval IDs

  const calculatePrice = (elapsedTime) => {
    console.log(activity)
    if (activity === null) {
      return;
    }
    let price_id = activity.price_id
    // console.log(price_id)
    // Calculate current price based on activity and price_id
    // Update the currentPrice state here
    // const activityPriceId = 2; // Replace with the actual logic to get price_id
    if (price_id === 2) {
      const hourlyPrice = 35; // Replace with the actual hourly price
      const hours = elapsedTime / 3600;
      const price = hourlyPrice * hours;
      // example: hours: 60/3600 = 0.01667
      // price: 0.01667 * 35 = 0.58
      setCurrentPrice(price.toFixed(2));
      formik.setFieldValue('price', price.toFixed(2));
    } else {
      const constantPrice = 0; // Replace with the actual constant price
      setCurrentPrice(constantPrice);
      formik.setFieldValue('price', constantPrice);
    }
  }

  function startStopwatch() {
    // Clear any existing interval
    if (intervalId) {
      clearInterval(intervalId);
      // Clear all intervals before starting a new one
      // clearAllIntervals();
      //TODO: test, with this, time doesn't update
    }
    // console.log(interval)
    // clearInterval(interval)

    // Set the start time to the current time
    startTimeRef.current = new Date().getTime();

    // Update the stopwatch every 1 second
    const newIntervalId = setInterval(updateStopwatch, 1000);
    intervalIdsRef.current.push(newIntervalId);
    setIntervalId(newIntervalId);
  }

  function clearAllIntervals() {
    // Clear all stored intervals
    intervalIdsRef.current.forEach(clearInterval);
    intervalIdsRef.current = []; // Reset the array after clearing
  }

  // Function to stop the stopwatch
  function stopStopwatch() {
    // Clear the interval
    // updateStopwatch();
    if (intervalId) {
      clearInterval(intervalId);
      console.log(intervalId);
      setActivity(null)
      console.log('is something happening?')
    }
  }

  function updateStopwatch() {
    // Get the current time
    // console.log(startTimeRef.current)
    if (startTimeRef.current) {
      let now = new Date().getTime();
      // console.log(now)
      // Find the elapsed time since the start
      let et = now - startTimeRef.current;
      // console.log(startTimeRef.current)
      let seconds = Math.floor(et / 1000);
      // formik.setFieldValue('time_cost_minutes', seconds);
      setElapsedTime(seconds);
      console.log(seconds)
    }
    // calculatePrice(seconds)
    // console.log(elapsedTime)
    // // Time calculations for hours, minutes, and seconds
    // var hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    // var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    // var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);

    // // Display the result in the element with id="stopwatch"
    // document.getElementById("stopwatch").innerHTML = hours + "h "
    //   + minutes + "m " + seconds + "s ";
  }

  useEffect(() => {
    console.log(activity);
  }, [activity]);

  useEffect(() => {
    console.log('isStopped' + isStopped);
  }, [isStopped]);

  useEffect(() => {
    if (!isRunning) {
      return;
    }
    console.log(activity)
    if (isEditing) {
      return;
    }
    if (!activity) {
      return;
    }
    calculatePrice(elapsedTime);
  }, [activity, isRunning, elapsedTime, isAgain]);

  useEffect(() => {
    if (isEditing) {
      return;
    }
    // console.log(elapsedTime)
    // console.log(isEditing)
    let timerInterval;
    let timerInterval2;
    //if activity not empty array

    if (isRunning) {
      console.log('isrunning changed')
      // if (isRunning && activity) {
      startStopwatch()
      // timerInterval = setInterval(() => {
      //   const currentTime = dayjs(); // Current time
      //   const startTime = dayjs(formik.values.start_time);
      //   const elapsedTime = currentTime.diff(startTime, 'seconds'); // Difference in seconds

      //   setElapsedTime(elapsedTime);
      //   formik.setFieldValue('time_cost_minutes', elapsedTime);
      // }, 1000);
      // timerInterval2 = setInterval(() => {
      //   calculatePrice(elapsedTime)
      // }, 1000);
    }
    else if (!isRunning) {
      stopStopwatch();
      console.log('isRunning' + isRunning)
      //   // console.log('test')
      //   // clearInterval(timerInterval);
      //   // clearInterval(timerInterval2);
    }
    // if (isStopped) {
    //   console.log('isStopped' + isStopped)
    //   stopStopwatch();
    //   // sendValuesToParent(elapsedTime, currentPrice);
    //   sendTimeToParent(elapsedTime);
    //   sendPriceToParent(currentPrice);
    // }

    return () => {
      // clearInterval(timerInterval);
      // clearInterval(timerInterval2);
      // stopStopwatch();
    }
  }, [isRunning]);
  // }, [isRunning, isStopped]);
  // }, [isRunning, activity]);
  //removed elapsedTime here, because it started to duplicate instances, 2x, 4x etc
  //TODO: maybe check for editing too

  //if is editing
  useEffect(() => {
    // console.log(formik.values.start_time)
    // console.log(formik.values.end_time)
    // console.log(elapsedTime)
    if (!isEditing || !activity) {
      return;
    }
    console.log(activity)
    if (formik.values.start_time && formik.values.end_time) {
      const st = dayjs(formik.values.start_time);
      const endTime = dayjs(formik.values.end_time);
      const elapsedTimeInSeconds = endTime.diff(st, 'second');
      console.log(elapsedTimeInSeconds)
      setElapsedTime(elapsedTimeInSeconds);
      // formik.setFieldValue('time_cost_minutes', elapsedTime);
      // calculatePrice(elapsedTimeInSeconds)
      // sendValuesToParent(elapsedTimeInSeconds, currentPrice);
      sendTimeToParent(elapsedTimeInSeconds);
    }

  }, [isEditing, formik.values.start_time, formik.values.end_time, elapsedTime]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    return formattedTime;
  };

  return (
    <>
      <Grid item xs={12} md={2}>
        <TextField id="outlined-basic" label={localeFormat("Time spent")} variant="outlined" placeholder={localeFormat("Time spent")} value={formatTime(elapsedTime)} readOnly />
      </Grid>
      <Grid item xs={12} md={2}>

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">{localeFormat("Cost")}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">€</InputAdornment>}
            label={localeFormat("Current cost")}
            value={currentPrice}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default Timer;
