import { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Grid } from '@mui/material';
import { getDuration } from './modules/getDuration';

const DataTable = ({ data, editItem = null, againItem = null, localeFormat, exportButton = null, footer = null }) => {
  // const getDuration = getDuration();
  //how to use the imported getDuration?


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'client_name', headerName: localeFormat('Client'), width: 200,
    },
    { field: 'activity', headerName: localeFormat('Activity'), width: 240 },
    {
      field: 'employee_name', headerName: localeFormat('Employee'), width: 200,
    },
    {
      field: 'client_id'
    },
    {
      field: 'user_id'
    },
    { field: 'activity_id' },
    {
      field: 'date', headerName: localeFormat('Date'), width: 110,
      valueGetter: (params) =>
        `${dayjs(params.row.date).format(localeFormat('dateFormat')) || ''}`,
    },
    {
      field: 'start_time',
      headerName: localeFormat('Start time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.start_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'end_time',
      headerName: localeFormat('End time'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        `${dayjs(params.row.end_time).format('HH:mm:ss') || ''}`,
    },
    {
      field: 'time_cost_minutes',
      headerName: localeFormat('Time spent'),
      type: 'time',
      width: 90,
      valueGetter: (params) =>
        getDuration(params.row.time_cost_minutes),
    },
    {
      field: 'price',
      headerName: localeFormat('Cost (€)'),
      type: 'number',
      width: 90,
    },
  ];

  const calcTotal = () => {
    let totalAmount = 0;
    let totalTime = 0;
    for (let i = 0; i < data.length; i++) {
      totalAmount += parseFloat(data[i].price) || 0; // Safely handle missing or invalid price
      totalTime += parseFloat(data[i].time_cost_minutes) || 0; // Safely handle missing or invalid time
    }
    return { totalAmount, totalTime };
  }

  const calcPaidFreeTime = () => {
    let paidTime = 0;
    let freeTime = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].price > 0) {
        paidTime += parseFloat(data[i].time_cost_minutes) || 0;
      }
      else {
        freeTime += parseFloat(data[i].time_cost_minutes) || 0;
      }
    }
    return { paidTime, freeTime };
  }

  const calcToday = () => {
    let todayTime = 0;
    const today = dayjs().format("YYYY-MM-DD"); // Get today's date in YYYY-MM-DD format

    for (let i = 0; i < data.length; i++) {
      const entryDate = dayjs(data[i].date).format("YYYY-MM-DD"); // Format entry date

      if (entryDate !== today) continue; // Skip non-today entries in 'today' mode

      todayTime += parseFloat(data[i].time_cost_minutes) || 0; // Handle missing/invalid time
    }

    return todayTime;
  }

  function roundTimeToDecimal(timeString) {
    let [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Convert minutes and seconds into a decimal from 0 to 9
    let decimal = Math.ceil(((minutes * 60 + seconds) / 3600) * 9) / 10;

    // Round the decimal part to one place
    let roundedTime = (hours + decimal).toFixed(1);

    return roundedTime;
  }

  //add thousand separators
  function formatAmount(amount) {
    amount = amount.toFixed(2);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const CustomFooter = ({ mode, ...props }) => {
    //todo: add monthly mode, the row below, other should work?
    let { totalAmount, totalTime } = calcTotal();
    let { paidTime, freeTime } = calcPaidFreeTime();
    let paidTimeFormatted = getDuration(paidTime);
    let freeTimeFormatted = getDuration(freeTime);
    // let todayTime = calcToday();
    // let fixedTime = getDuration(6 * 3600);
    let totalTimeFormatted = getDuration(totalTime);

    // Format totalAmount and totalTime based on mode
    if (totalAmount > 0) {
      totalAmount = formatAmount(totalAmount);
    }


    return (
      <>
        <div style={{ padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ccc' }}>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <strong>
                {localeFormat("Total Time")}:
              </strong>&nbsp;
              {totalTimeFormatted + ' (' + roundTimeToDecimal(totalTimeFormatted) + ')'}
            </Grid>
            <Grid item md={2}>
              <strong>
                {localeFormat("Unpaid time")}:
              </strong>&nbsp;
              {freeTimeFormatted + ' (' + roundTimeToDecimal(freeTimeFormatted) + ')'}
            </Grid>
            <Grid item md={2}>
              <strong>
                {localeFormat("Paid time")}:
              </strong>&nbsp;
              {paidTimeFormatted + ' (' + roundTimeToDecimal(paidTimeFormatted) + ')'}
            </Grid>

            <Grid item md={2}>
              <strong>
                {localeFormat("Total Amount")}:
              </strong>&nbsp;
              {`€ ${totalAmount}`}
            </Grid>

            {/* <Grid item md={3}>
              <strong>
                {mode === 'today' ? localeFormat("Today's hours worked") : localeFormat("Total Time")}:
              </strong>&nbsp;
              {mode === 'today' ? todayTime : totalTime + ' (' + roundedTime + ')'}
            </Grid>
            {mode !== 'monthly' && (
              <Grid item md={3}>
                <strong>
                  {mode === 'today' ? localeFormat("Today's goal") : localeFormat("Total Amount")}:
                </strong>&nbsp;
                {mode === 'today' ? fixedTime : `€ ${totalAmount}`}
              </Grid>
            )} */}
          </Grid>
        </div>
        <GridPagination {...props} />
      </>
    );
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        columnVisibilityModel={{
          // Hide columns client_id, the other columns will remain visible
          client_id: false,
          user_id: false,
          activity_id: false,
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        slots={{
          ...(exportButton !== null && { toolbar: CustomToolbar }),
          ...(footer !== null && { footer: (props) => <CustomFooter mode={footer} {...props} /> })
        }}
        pageSizeOptions={[10, 25, 100]}
      // checkboxSelection
      />
    </div>
  );
}

export default DataTable;