import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import useCustomTranslation from 'hooks/useCustomTranslation';

const SelectedFilters = ({ startDate, endDate, client, activity, employee }) => {
  const { t } = useCustomTranslation();
  return (
    <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 2 }}>
      {(startDate || endDate || client?.client_name) && (
        <Typography variant="body">{t('Selected Filters')}:</Typography>
      )}
      {startDate && (
        <Chip
          label={t('Start Date') + `: ${new Date(startDate).toLocaleDateString()}`}
          variant="outlined"
        />
      )}
      {endDate && (
        <Chip
          label={t('End Date') + `: ${new Date(endDate).toLocaleDateString()}`}
          variant="outlined"
        />
      )}
      {client?.client_name && (
        <Chip label={t('Client') + `: ${client.client_name}`} variant="outlined" />
      )}
      {activity?.activity && (
        <Chip label={t('Activity') + `: ${activity.activity}`} variant="outlined" />
      )}
      {employee?.employee_name && (
        <Chip label={t('Employee') + `: ${employee.employee_name}`} variant="outlined" />
      )}
    </Box>
  );
};

export default SelectedFilters;
