import React, { useState, useEffect } from "react";
import { Grid, TextField, Box, Autocomplete } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'react-intl'; // Import the useIntl hook
import dayjs from 'dayjs';
import en from 'date-fns/locale/en-GB';
import et from 'date-fns/locale/et';
import { useLocale } from './contexts/LocaleContext';
import Button from '@mui/material/Button';
import StandaloneToggleButton from 'components/StandaloneToggleButton';
import SelectedFilters from 'components/SelectedFilters';

const DateRangePicker = ({ data, clients, activities, employees, setFilteredRows, alignment }) => {
  const [client, setClient] = useState(null);
  const [activity, setActivity] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const intl = useIntl();
  const { locale } = useLocale();
  const [chooseDates, setChooseDates] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    // If start date is set and end date is before start, reset the end date
    if (endDate && date && date > endDate) {
      setEndDate(null);
    }
  };

  const handleClientChange = (event, value) => {
    setClient(value);
  };

  const handleActivityChange = (event, value) => {
    setActivity(value);
  };

  const handleEmployeeChange = (event, value) => {
    setEmployee(value);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  function localeFormat(id) {
    return intl.formatMessage({ id });
  }

  //if different toggle selected, reset all data
  //eg if either client or activity is selected, reset employee etc.
  useEffect(() => {
    handleChangeAlignment();
  }, [alignment]);

  const thisMonth = () => {
    const firstDate = dayjs().startOf('month');
    setStartDate(firstDate);

    const lastDate = dayjs().endOf('month');
    setEndDate(lastDate);
  }

  const prevMonth = () => {
    const firstDate = dayjs().subtract(1, 'month').startOf('month');
    setStartDate(firstDate);

    const lastDate = dayjs().subtract(1, 'month').endOf('month');
    setEndDate(lastDate);
  }

  const handleApplyFilter = () => {
    const filteredRows = filterRows(data, client, startDate, endDate);
    console.log(filteredRows);
    setFilteredRows(filteredRows);
  };

  const handleCancelFilter = () => {
    setFilteredRows(data);
    setClient(null);
    setActivity(null);
    setEmployee(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleCancelDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleChangeAlignment = () => {
    setClient(null);
    setActivity(null);
    setEmployee(null);
  };


  const filterRows = (data, clientIdFilter, activityFilter, employeeFilter, startDateFilter, endDateFilter) => {
    console.log(clientIdFilter);
    return data.filter((row) => {
      const matchesClientId = clientIdFilter && row.client_id === clientIdFilter.id ? true : false;
      const matchesActivity = activityFilter && row.activity_id === activityFilter.id ? true : false;
      const matchesEmployee = employeeFilter && row.user_id === employeeFilter.id ? true : false;
      const matchesStartDate = startDateFilter && new Date(row.date) >= new Date(startDateFilter) ? true : false;
      const matchesEndDate = endDateFilter && new Date(row.date) <= new Date(endDateFilter) ? true : false;
      console.log(matchesClientId, matchesActivity, matchesEmployee, matchesStartDate, matchesEndDate);
      //dates have to always return a result
      //then we check if either of the other filters are selected: client, activity, employee
      //and, if the above are not selected, we still need to return the date matches (aka true if no other filters are selected)
      //or finally, if only no dates are selected, return the other filtered results
      return (
        (
          (matchesStartDate && matchesEndDate) &&
          (matchesClientId || matchesActivity || matchesEmployee ||
            (!clientIdFilter && !activityFilter && !employeeFilter)
          )
        )
        ||
        (
          (!matchesStartDate && !matchesEndDate) &&
          (matchesClientId || matchesActivity || matchesEmployee)
        )
      );

    });
  };

  // Update filtered rows whenever filter criteria change
  const handleFilterChange = () => {
    const newFilteredRows = filterRows(data, client, activity, employee, startDate, endDate);
    setFilteredRows(newFilteredRows);
  };

  useEffect(() => {
    if (client || activity || employee || (startDate && endDate)) {
      handleFilterChange();
      console.log('Filtering data');
    }
  }, [client, activity, employee, startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <Grid container spacing={1}>
        <Grid item md={2}>
          {alignment === 'client' && (
            <Autocomplete
              id="clients"
              name="client"
              onChange={handleClientChange}
              value={client}
              //for testing duplicate client names
              // options={clients.map((option) => option.client_name + ' (' + option.id + ')' || "")}
              options={clients}
              getOptionLabel={(option) => option.client_name}
              renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type a client")} />}
            />
          )}
          {alignment === 'activity' && (
            <Autocomplete
              id="activities"
              name="activity"
              onChange={handleActivityChange}
              value={activity}
              options={activities}
              getOptionLabel={(option) => option.activity}
              renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type an activity")} />}
            />
          )}
          {alignment === 'employee' && (
            <Autocomplete
              id="employees"
              name="employee"
              onChange={handleEmployeeChange}
              value={employee}
              options={employees}
              getOptionLabel={(option) => option.employee_name}
              renderInput={(params) => <TextField {...params} label={localeFormat("Choose or type an employee")} />}
            />
          )}
        </Grid>
        <Grid item md={1}>
          <Button variant="outlined" onClick={prevMonth} sx={{ height: '100%', width: '100%' }}>
            {localeFormat("Previous month")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="outlined" onClick={thisMonth} sx={{ height: '100%', width: '100%' }}>
            {localeFormat("This month")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="text" onClick={handleCancelDates} sx={{ height: '100%', width: '100%' }}>
            {localeFormat("Clear Dates")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button variant="text" onClick={handleCancelFilter} sx={{ height: '100%', width: '100%' }}>
            {localeFormat("Clear All Filters")}
          </Button>
        </Grid>
        <Grid item md={1}>
          <StandaloneToggleButton selected={chooseDates} setSelected={setChooseDates} />
        </Grid>
        {chooseDates && (
          <>
            <Grid item md={2}>
              <DatePicker
                label={localeFormat("Start Date")}
                format={localeFormat('dateFormat')}
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item md={2}>
              <DatePicker
                label={localeFormat("End Date")}
                format={localeFormat('dateFormat')}
                value={endDate}
                onChange={handleEndDateChange}
                minDate={startDate || undefined} // Prevent selecting end date before start date
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            {/* <Grid item md={1}>
              <Button variant="outlined" onClick={handleApplyFilter}>
                {localeFormat("Apply Dates")}
              </Button>
            </Grid> */}
          </>
        )}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectedFilters startDate={startDate} endDate={endDate} client={client} activity={activity} employee={employee} />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
